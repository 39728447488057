<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Intégrer les données KYC à la blockchain</h2>
		</div>

		<h2>
			Principe de base</h2>
		<p>

			Les données KYC/AML sont indispensables pour authentifier un compte. Cette authentification n'est pas obligatoire mais elle donne accès au marché primaire.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Emma va utiliser le wallet de Fraction.re pour la première fois, elle va le configurer en important ses clés.
			<b />Ensuite, elle intégrer les données KYC/AML
			<br />
		</p>

		<h3>
			Configuration du Wallet</h3>
		<p>Vous devez disposer d'un jeu de clés dans la blockchain Stellar.<a href="https://fraction.immo/open"> Si ce n'est pas le cas, lire l'article suivant </a>
		</p>
		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/berlcoin-1.png" />
				<br /> <br /> <b> Emma se rend sur <a target="_target"
						 href="https://wallet.fraction.re">https://wallet.fraction.re</a></b> et scanne son adresse publique<br />
			</div><br />
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/berlcoin-2.png" />
				<br /> <br /> <b> Emma choisit de remplacer la clé privé par un code PIN. Elle scanne sa clé privée et choisit un code de 6 caractères. </b><br />
				<br />
			</div>

			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/berlcoin-3.png" />
				<br /> <br />
				<b>Il est aussi possible d'utiliser un système de double authentification en scannant le QRCode dans Google Authentificator ou FreeOTP et en saisisant le code de vérification.
				</b> <br /><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/berlcoin-4.png" /><br />
				<br />
				<b>Le compte d'Emma s'affiche.
				</b> <br />
			</div>

			<h3 class="my-12">
				Intégration KYC</h3>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/kyc.png" /><br />
				<br /> <br />
				<b> Emma clique sur le lien reçu par mail, le wallet affiche les données crypté, Emma valide, désormais elle pourra signer des contrats de droits français.
				</b> <br />
			</div>





		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Emma a payé 1 XLM (ici à 0.20 euro ) pour l'intégration.

			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
